/**
 * Mobile menu toggle button @ Website
 *
 */
document.addEventListener("DOMContentLoaded", function () {
  const mobileMenuButton = document.querySelector("#menu-icon");
  const header = document.querySelector("#header");
  if (mobileMenuButton !== null && header !== null) {
    mobileMenuButton.addEventListener("click", function () {
      mobileMenuButton.classList.toggle("animate");
      header.classList.toggle("mobile-menu-opened");
    });
  }
});
